import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Button,
  TextField,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

import {
  GetAdminPromoCodesQuery,
  GetAdminPromoCodesQueryVariables,
  PromoCodeSourceKind,
} from '../../../generated/graphql';
import { CreatePromoCodeDialogButton } from './CreatePromoCodeDialogButton';
import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';
import { PromoCode, promoCodeInfoFragment } from './PromoCode';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginBottom: theme.spacing(2),
  },
  promoCodeContainer: {
    marginTop: theme.spacing(1),
  },
  orderIdSearchContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
  },
  orderIdInput: {
    minWidth: 300,
  },
}));

const getAdminPromoCodes = gql`
  query getAdminPromoCodes(
    $after: String
    $first: Int
    $sourceKind: PromoCodeSourceKind
    $orderId: String
  ) {
    admin {
      adminPromoCodesLookup(
        first: $first
        after: $after
        sourceKind: $sourceKind
        orderId: $orderId
      ) {
        edges {
          node {
            ...promoCodeInfo
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${promoCodeInfoFragment}
`;

const TABS = [PromoCodeSourceKind.Admin, PromoCodeSourceKind.Youzan];

export const PromoCodeLookup = () => {
  const styles = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [orderId, setOrderId] = useState('');

  const [getData, { data, error }] = useLazyQuery<
    GetAdminPromoCodesQuery,
    GetAdminPromoCodesQueryVariables
  >(getAdminPromoCodes, { variables: { first: 100 } });

  useEffect(() => {
    setOrderId('');
    getData({
      variables: {
        sourceKind: TABS[tabIndex],
      },
    });
  }, [tabIndex]);

  const searchWithOrderId = () => {
    getData({
      variables: {
        orderId,
      },
    });
  };

  return (
    <>
      <h1>Promo Codes</h1>
      <div className={styles.buttonContainer}>
        <CreatePromoCodeDialogButton />
      </div>

      <Paper square>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_evt, newVal) => setTabIndex(newVal)}
        >
          {TABS.map(tab => (
            <Tab key={tab} label={tab} />
          ))}
        </Tabs>
        {tabIndex === 1 && (
          <div className={styles.orderIdSearchContainer}>
            <TextField
              className={styles.orderIdInput}
              id="order-id"
              label="Find Order ID"
              variant="outlined"
              onChange={evt => setOrderId(evt.target.value)}
            />
            <Button disabled={!orderId} onClick={searchWithOrderId}>
              Find
            </Button>
          </div>
        )}
      </Paper>
      {data?.admin?.adminPromoCodesLookup.edges?.map(
        edge =>
          edge?.node && (
            <div key={edge.node.id} className={styles.promoCodeContainer}>
              <PromoCode info={edge.node} />
            </div>
          ),
      )}
      <GraphQLErrorDisplay error={error} />
    </>
  );
};
