import * as React from 'react';

import Layout from '../components/common/Layout';
import Page from '../components/common/Page';
import Container from '../components/common/Container';
import { PromoCodeLookup } from '../components/pages/PromoCodeLookup';

const PromoCodeLookupPage = () => {
  return (
    <Layout>
      <Page>
        <Container>
          <PromoCodeLookup />
        </Container>
      </Page>
    </Layout>
  );
};

export default PromoCodeLookupPage;
