import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { FC } from 'react';

interface MenyButtonProps {
  options: readonly string[];
  onOptionPress: (selected: string) => void;
}

export const PromoCodeMenuButton: FC<MenyButtonProps> = ({
  options,
  onOptionPress,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelected = (option: string) => {
    handleClose();
    onOptionPress(option);
  };

  return (
    <>
      <IconButton aria-label="settings" onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="optionsMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem key={option} onClick={() => handleOptionSelected(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
