import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  FormGroup,
  TextField,
  DialogContentText,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  AdminCreatePromoCodeMutation,
  AdminCreatePromoCodeMutationVariables,
  PromoCodeProduct,
} from '../../../generated/graphql';
import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';
import { PromoCode, promoCodeInfoFragment } from './PromoCode';

export interface GiveCreditsButtonProps {
  onSubmit: (input: CreditsDialogResult) => void;
}

export interface CreditsDialogResult {
  credits: number;
  reason: string;
}

const PRODUCTS = [
  {
    title: 'Lifetime - A.I Tutors',
    value: PromoCodeProduct.Lifetime,
  },
  {
    title: '1 Year - A.I Tutors',
    value: PromoCodeProduct.OneYear,
  },
  {
    title: '3 Months - A.I Tutors',
    value: PromoCodeProduct.ThreeMonths,
  },
  {
    title: '1 Month - A.I Tutors',
    value: PromoCodeProduct.OneMonth,
  },
  {
    title: '7 Days - A.I Tutors',
    value: PromoCodeProduct.SevenDays,
  },
  {
    title: '1 Hour - Teachers',
    value: PromoCodeProduct.OneHourTeacher,
  },
  {
    title: 'Credit Bundle - 3 credits',
    value: PromoCodeProduct.ThreeCreditsBundle,
  },
] as const;

const useStyles = makeStyles(theme => ({
  productSelect: {
    marginBottom: theme.spacing(3),
    minWidth: 250,
  },
  confirmInput: {
    marginBottom: theme.spacing(1),
    minWidth: 500,
  },
}));

const adminCreatePromoCode = gql`
  mutation adminCreatePromoCode($input: AdminCreatePromoCodeInput!) {
    adminCreatePromoCode(input: $input) {
      promoCode {
        ...promoCodeInfo
      }
    }
  }
  ${promoCodeInfoFragment}
`;

export const CreatePromoCodeDialogButton: FC = () => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hasCalledMutation, setHasCalledMutation] = React.useState(false);
  const [product, setProduct] = React.useState<PromoCodeProduct>(
    PRODUCTS[4].value,
  );
  const [reason, setReason] = React.useState<string | undefined>();
  const [title, setTitle] = React.useState<string | undefined>();
  const [maxRedemptions, setMaxRedemptions] = React.useState(1);

  const [createPromoCode, { data, error, loading }] = useMutation<
    AdminCreatePromoCodeMutation,
    AdminCreatePromoCodeMutationVariables
  >(adminCreatePromoCode);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setHasCalledMutation(false);
  };

  const handleConfirm = () => {
    if (maxRedemptions && reason && title && product) {
      createPromoCode({
        variables: {
          input: {
            maxRedemptions,
            reason,
            title,
            productId: product,
          },
        },
      });
      setHasCalledMutation(true);
    }
  };

  const handleChangeDuration = (evt: { target: { value: any } }) => {
    setProduct(evt.target.value);
  };

  const hasData = data && !loading && hasCalledMutation;

  return (
    <div>
      <Button
        style={{ marginTop: 8 }}
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        Create new Promo Code
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create Promo Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in the fields bellow to create a promo code. Each promo code
            corresponds to a product, select the product and how many times the
            code can be used.
          </DialogContentText>
          <FormGroup row={false}>
            <FormControl className={styles.productSelect}>
              <InputLabel id="product-select-label">Choose product</InputLabel>
              <Select
                labelId="product-select-label"
                id="product-select"
                value={product}
                onChange={handleChangeDuration}
              >
                {PRODUCTS.map(opt => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.title}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                autoFocus
                margin="dense"
                type="number"
                id="name"
                label="Max redemtions"
                fullWidth
                value={maxRedemptions}
                onChange={evt => {
                  const value = Number.parseInt(evt.target.value, 10);
                  if (value >= 1) setMaxRedemptions(value);
                }}
              />
              <TextField
                margin="dense"
                id="name"
                label="Title of the promo code"
                fullWidth
                onChange={evt => setTitle(evt.target.value)}
              />
              <TextField
                margin="dense"
                id="name"
                label="Reason for the promo code"
                fullWidth
                onChange={evt => setReason(evt.target.value)}
              />
            </FormControl>
          </FormGroup>
          {data?.adminCreatePromoCode?.promoCode && (
            <PromoCode info={data.adminCreatePromoCode?.promoCode} />
          )}
        </DialogContent>
        <DialogActions>
          {hasData ? (
            <>
              <Button onClick={handleClose}>Close</Button>
            </>
          ) : (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleConfirm} disabled={hasData}>
                Create code
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <GraphQLErrorDisplay error={error} />
    </div>
  );
};
