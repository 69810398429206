import React, { FC } from 'react';
import { Link } from 'gatsby';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  makeStyles,
  FormGroup,
  TextField,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { userInfoUserFragment } from '../../User/UserInfo';
import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';
import {
  AdminRedeemPromoCodeMutation,
  AdminRedeemPromoCodeMutationVariables,
} from '../../../generated/graphql';

export interface GiveCreditsButtonProps {
  onSubmit: (input: CreditsDialogResult) => void;
}

export interface CreditsDialogResult {
  credits: number;
  reason: string;
}

const useStyles = makeStyles(theme => ({
  productSelect: {
    marginBottom: theme.spacing(3),
    minWidth: 250,
  },
  confirmInput: {
    marginBottom: theme.spacing(1),
    minWidth: 500,
  },
}));

const adminRedeemPromoCode = gql`
  mutation adminRedeemPromoCode(
    $input: AdminRedeemPromoCodeWithPhoneNumberInput!
    $canReadSensitiveUserData: Boolean = false
  ) {
    adminRedeemPromoCodeWithPhoneNumber(input: $input) {
      user {
        ...userInfoUser
      }
    }
  }
  ${userInfoUserFragment}
`;

export interface RedeemPromoCodeDialogProps {
  open: boolean;
  code: string;
  onCloseClick: () => void;
}

export const RedeemPromoCodeDialog: FC<RedeemPromoCodeDialogProps> = ({
  open,
  onCloseClick,
  code,
}) => {
  const styles = useStyles();
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('86');
  const [reason, setReason] = React.useState<string | undefined>();

  const [redeemPromoCode, { data, error, loading }] = useMutation<
    AdminRedeemPromoCodeMutation,
    AdminRedeemPromoCodeMutationVariables
  >(adminRedeemPromoCode);

  const handleConfirm = () => {
    if (reason && phoneNumber && countryCode) {
      redeemPromoCode({
        variables: {
          input: {
            code,
            phoneNumber,
            countryCode,
            reason,
          },
        },
      });
    }
  };

  const submitDisabled =
    loading || !!data || !reason || !phoneNumber || !countryCode;

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Redeem Promo Code <i>{code}</i>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To redeem the promo code for a user, fill in the users phone number
            details bellow and the promo code will be redeemed on their behalf.
            You must give a reason for why the code is being redeemed for this
            user.
          </DialogContentText>
          <FormGroup row={false}>
            <FormControl className={styles.productSelect}>
              <TextField
                margin="dense"
                id="countryCode"
                label="Country Code"
                fullWidth
                value={countryCode}
                onChange={evt => setCountryCode(evt.target.value)}
              />
              <TextField
                autoFocus
                margin="dense"
                id="phoneNumber"
                label="Phone Number"
                fullWidth
                value={phoneNumber}
                onChange={evt => setPhoneNumber(evt.target.value)}
              />
              <TextField
                margin="dense"
                id="name"
                label="Reason"
                fullWidth
                onChange={evt => setReason(evt.target.value)}
              />
            </FormControl>
          </FormGroup>
          {data && !error && (
            <>
              <Typography variant="h6" component="h2">
                Successfully redeemed code
              </Typography>
              <Link
                to={`/user/${data.adminRedeemPromoCodeWithPhoneNumber?.user.id}`}
              >
                User page
              </Link>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseClick}>Close</Button>
          <Button onClick={handleConfirm} disabled={submitDisabled}>
            Redeem code
          </Button>
        </DialogActions>
      </Dialog>
      <GraphQLErrorDisplay error={error} />
    </div>
  );
};
